<template>
  <navbar position="fixed" type="primary" menu-classes="ml-auto">
    <template slot-scope="">
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        Grooveworks
      </router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          Drums & software development
        </div>
      </el-popover>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a class="nav-link" href="#about">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#dev">Software Development</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#drums">Drums</a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { NavbarToggleButton, Navbar } from "@/components";
import { Popover } from "element-ui";

export default {
  name: "main-navbar",
  components: {
    Navbar,
    [Popover.name]: Popover,
  },
};
</script>

<style scoped></style>
