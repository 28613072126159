<template>
  <div>
    <div class="page-header">
      <parallax
        class="page-header-image"
        style="background-image: url('img/amstel-min.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <img class="n-logo" src="img/logogw.png" alt="" />
          <h1 class="title">Grooveworks<br />web & music</h1>
          <div class="text-center">
            <a
              href="https://www.linkedin.com/in/david-de-vries"
              class="btn btn-primary btn-icon btn-round"
            >
              <i class="fab fa-linkedin"></i>
            </a>
            <a
              href="https://www.facebook.com/grooveworksNL"
              class="btn btn-primary btn-icon btn-round"
            >
              <i class="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/david-de-vries"
              class="btn btn-primary btn-icon btn-round"
            >
              <i class="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="starter-page">
      <!--      <div class="section section-images">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hero-images-container">
                <img src="img/macbook.png" alt="" />
              </div>
              <div class="hero-images-container-1">
                <img src="img/snare41.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div id="about" class="section section-about-us">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">Who?</h2>
              <h5 class="description">
                Grooveworks is the name of the company owned by David de Vries;
                professional drummer and software developer. Both the company
                and the person are based in Amsterdam and born in Haarlem.
                Please see my LinkedIn profile for all the things I did before:
                <div class="text-center">
                  <a
                    href="https://www.linkedin.com/in/david-de-vries"
                    class="btn btn-primary btn-icon btn-round"
                  >
                    <i class="fab fa-linkedin"></i>
                  </a>
                </div>
              </h5>
            </div>
          </div>
          <div class="separator separator-primary"></div>
          <div class="section-story-overview">
            <div class="row">
              <div class="col-md-6">
                <!-- First image on the left side -->
                <div
                  class="image-container image-left"
                  style="background-image: url('img/linkedin.png')"
                >
                  <p class="blockquote blockquote-primary">
                    "Anyone can make the simple complicated. Creativity is
                    making the complicated simple."
                    <br />
                    <br />
                    <small>-Charles Mingus</small>
                  </p>
                </div>
                <!-- Second image on the left side of the article -->
                <card
                  raised
                  class="card-nav-tabs"
                  header-classes="card-header-success"
                >
                  <blockquote class="blockquote blockquote-primary mb-0">
                    <h2>Groove</h2>
                    <p>
                      <ol>
<li>An adjective describing a rhythmic quality of music that is emotionally communicative and soulful; used for music moving at a steady beat.</li>
<li>A verb meaning to smoothly interact, maintain or improve relations with business associates, a person, or a situation.</li>
                      </ol>

                      <i>
                      <ol>
<li>The groove in this song just isn't right yet.</li>
<li>Yeah, I need to groove that guitar player, get him in on another recording session soon.</li>
                      </ol></i>
                    </p>
                    <footer class="blockquote-footer">
                      urbandictionary.com
                    </footer>
                  </blockquote>
                </card>
              </div>
              <div class="col-md-6">
                <!-- First image on the right side, above the article -->
                <div
                  class="image-container image-right"
                  style="background-image: url('img/drums2.jpg')"
                ></div>
                <h3>
                  Web and music: software and drums
                </h3>
                <p>
                  Having studied both drums (Conservatorium Alkmaar) and
                  Software Engineering (HAN-CMD/I Arnhem), Grooveworks is the
                  result of combining both passions into one company that is
                  100% me.
                </p>
                <h4 id="dev">Software developer</h4>
                <p>
                  Since I was still in university I've always been a full stack
                  web developer, specialist in C# .Net and JavaScript. Now with
                  over 12 years of experience, I've worked for big corporate
                  clients, high traffic websites and startups. I have many years
                  of experience from working with databases to building the
                  front end of large applications. Some keywords are: Azure,
                  .Net, JavaScript, C#, SQL, F#, Angular, ReactJs,
                  Microservices, Docker and Kubernetes. I'm available on
                  contract basis to help you build your next web application.
                </p>
                <h4 id="drums">Drummer</h4>
                <p>
                  I started playing drums when I was only 12 years old. Around
                  10 years after that I decided I wanted to make it into my
                  profession and started my study at the Concervatory of
                  Alkmaar. Since then I've been teaching drums for many years at
                  several music schools around Haarlem. Next to that I've played
                  a lot of different music styles in many bands and projects. I
                  have professional equipment and am currently available for
                  drum lessons and session work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Parallax, Card } from "@/components";

export default {
  name: "starter",
  bodyClass: "starter-page",
  components: {
    Parallax,
    Card,
  },
};
</script>
<style>
.starter-page {
  min-height: calc(100vh - 95px);
}
</style>
